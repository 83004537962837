.AddOpportunitiesModalContainer .form-group {
  text-align: left;
  margin-bottom: 20px
}

.AddOpportunitiesModalContainer .form-group label {
  margin-bottom: 1.5rem;
  margin-right: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.AddOpportunitiesModalContainer .form-control {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 50%;
  margin-left: 0px;
  margin-bottom: 5px;
}

.AddOpportunitiesModalContainer .form-control-textarea {
  width: 100%;
  min-height: 150px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: vertical;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.AddOpportunitiesModalContainer .form-control-textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}